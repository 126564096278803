import React from "react"
import SEO from "../components/seo"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function AmaPage(props) {
  return (
    <div id="ama-monochrome">
      <VideoMobile
        title="Ama, Monochrome • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/302436895?&title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="Ama, Monochrome • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/302436895?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
      <SEO title={props.title} />
    </div>
  )
}
